import React, { useEffect, useState } from "react";
import {
  Container,
  LoadingDiv,
  MyDiv,
  PortfolioContainer,
  PortfolioWrapper,
} from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";
import { Spinner } from "react-bootstrap";
import Modal from "../Components/MyModal";
import styled from "styled-components";

export default function Portfolio() {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [description, setDescription] = useState("");

  const getDetails = async () => {
    try {
      await fetch(`${LiveBaseURL}api/v1/portfolio_data`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const styles1 = {
    flexDirection: "row-reverse",
  };
  const styles2 = {
    flexDirection: "column",
  };

  const OpenModalForDescription = (payload) => {
    setDescription(payload.description);
    setModalShow(true);
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <PortfolioWrapper>
      {modalShow && (
        <Modal
          show={modalShow}
          description={description}
          onHide={() => setModalShow(false)}
        />
      )}
      <Container>
        <PortfolioContainer>
          {data.length > 0 ? (
            data.map((ele, index) => (
              <>
                {index % 2 === 0 ? (
                  <MyDiv>
                    <div>
                      <h4>{ele.title}</h4>
                      <h2>{ele.subtitle}</h2>
                      <ul>
                        {ele.bulletpoints.map((items) => (
                          <>
                            {items.link === "yes" ? (
                              <li>
                                <a href={items.bullet_point}>
                                  {items.description}
                                </a>
                              </li>
                            ) : (
                              <DesBulletPoint
                             
                                onClick={() => OpenModalForDescription(items)}
                              >
                                {items.bullet_point}
                              </DesBulletPoint>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                    <img src={ele.image} alt="" />
                  </MyDiv>
                ) : (
                  <MyDiv
                    style={window.screen.availWidth > 500 ? styles1 : styles2}
                  >
                    <div>
                      <h4>{ele.title}</h4>
                      <h2>{ele.subtitle}</h2>
                      <ul>
                        {ele.bulletpoints.map((items) => (
                          <>
                            {items.link === "yes" ? (
                              <li>
                                <a href={items.bullet_point}>
                                  {items.description}
                                </a>
                              </li>
                            ) : (
                              <DesBulletPoint
                                onClick={() => OpenModalForDescription(items)}
                              >
                                {items.bullet_point}
                              </DesBulletPoint>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                    <img src={ele.image} alt="" />
                  </MyDiv>
                )}
              </>
            ))
          ) : (
            <LoadingDiv>
              <Spinner
                animation="grow"
                style={{ width: "70px", height: "70px", color: "#26346e" }}
              />
            </LoadingDiv>
          )}
        </PortfolioContainer>
      </Container>
    </PortfolioWrapper>
  );
}


const DesBulletPoint = styled.li`

&:hover{
  color:red;
  cursor:pointer;
}


`