import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "../Components/Header";

export default function Layout() {
  return (
    <div>
      <Wrapper>
        <Header />
        <Outlet />
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
