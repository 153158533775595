import React, { useEffect, useState } from "react";
import { Carousel, Spinner } from "react-bootstrap";
import styledComponents from "styled-components";
import { BlackLayer, Container, SilderTextBox } from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";

export default function Features() {
  const [data, setData] = useState([]);
  const getDetails = async () => {
    try {
      await fetch(`${LiveBaseURL}api/v1/features_data`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <BlackLayer />
      <Carousel style={{ position: "relative" }}>
        {data.length > 0 ? (
          data?.map((ele) => (
            <Carousel.Item interval={5000}>
              <img
                className="d-block w-100"
                style={{ height: "100vh", objectFit: "cover" }}
                src={ele.image}
                alt="First slide"
              />

              <Container>
                <SilderTextBox>
                  <h2>{ele.title}</h2>
                  <h3>{ele.subtitle}</h3>
                  <p>{ele.description}</p>
                </SilderTextBox>
              </Container>
            </Carousel.Item>
          ))
        ) : (
          <LoadingDiv>
            <Spinner
              animation="grow"
              style={{ width: "70px", height: "70px", color: "#fff" }}
            />
          </LoadingDiv>
        )}
      </Carousel>
    </div>
  );
}

const LoadingDiv = styledComponents.div`

width:100%;
height:100vh;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(180deg,#1bdbe8 0%,#145da0 68.75%);


`;
