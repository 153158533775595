import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import BookADemo from "../Pages/BookADemo";
import Comparison from "../Pages/Comparison";
import Features from "../Pages/Features";
import Home from "../Pages/Home";
import Portfolio from "../Pages/Portfolio";
import Privacy from "../Pages/Privacy";

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<BookADemo />} />
          <Route path="/Services" element={<Features />} />
          {/* <Route path="/Comparison" element={<Comparison />} /> */}
          {/* <Route path="/Portfolio" element={<Portfolio />} /> */}
          <Route path="/Privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
