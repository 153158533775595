import React from "react";
import { useField } from "formik";
import styled from "styled-components";

export default function InputField(props) {
  const [field, meta] = useField(props.name);
  return (
    <FormikWrap>
      <Input {...field} {...props} />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </FormikWrap>
  );
}

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #ffffff;
  color: #000;
  box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);
  border-radius: 10px;
  margin: 10px 0;
  border-style: none;
  padding: 0 20px;

  ::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #999999;
  }
`;

const FormikWrap = styled.div`
  width: 100%;
  .error {
    margin: 0;
    color: #222222;
    font-size: 10px;
    font-weight: 500;
    text-align: start;
    padding: 0px 20px 10px 20px;
  }
`;
