import React from "react";
import { Modal } from "react-bootstrap";

export default function MyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <h4>Description</h4>
        <p>
       {props.description}
        </p>
      </Modal.Body>
    </Modal>
  );
}
