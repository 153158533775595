import React from "react";
import { Container, FooterText, FooterWrapper } from "../GobalStyle";
import { FooterImage } from "../Utils/Images";

export default function Footer({ style }) {
  return (
    <FooterWrapper style={style}>
      <Container>
        <FooterText>
          <img src={FooterImage} alt="CONTOLIO" />
          <span>Copyrights reserved by Beast Technologies LLC</span>
        </FooterText>
      </Container>
    </FooterWrapper>
  );
}
