import React from "react";
import { useField } from "formik";
import styled from "styled-components";

export default function DescriptionField(props) {
  const [field, meta] = useField(props.name);
  return (
    <FormikWrap>
      <TextArea {...field} {...props} />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </FormikWrap>
  );
}

const TextArea = styled.textarea`
  width: 100%;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px 20px;

  ::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #999999;
  }
`;

const FormikWrap = styled.div`
  width: 100%;
  .error {
    margin: 0;
    color: #222222;
    font-size: 10px;
    font-weight: 500;
    text-align: start;
    padding: 0px 20px 10px 20px;
  }
`;
