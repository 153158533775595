import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";

import {
  BlackLayer,
  Button,
  Container,
  FirstLayout,
  ImageWrapper,
  MainContext,
  SecondLayout,
  SecondTextContent,
  SilderTextBox,
} from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";
import { HomeSecondImage } from "../Utils/Images";

export default function Home() {
  return (
    <>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <Footer />
    </>
  );
}

const FirstSection = () => {
  const navigate = useNavigate();

  return (
    <FirstLayout>
      <BlackLayer />
      <Container>
        <MainContext>
          <h4>Modernize your business with </h4>
          <h2>digital solutions </h2>

          <p>
            Beast Technologies offers multiple services such as designing and
            developing custom business Software solutions, Mobile Apps and
            general IT strategic consultancy services.
          </p>
          <Button className="m-0" onClick={() => navigate("/BookADemo")}>
            Contact Us
          </Button>
        </MainContext>
      </Container>
    </FirstLayout>
  );
};

const SecondSection = () => {
  const navigate = useNavigate();

  return (
    <SecondLayout>
      <Container>
        <SecondLayoutContainer>
          <ImageWrapper>
            <img src={HomeSecondImage} alt="HouseImage" />
          </ImageWrapper>

          <SecondTextContent>
            <h4>Build digital solutions aligned with your needs</h4>
            <h2>Think of a technology as an investment, not as a cost</h2>
            <p>
              No Matter what your requirements are, we would help you to convert
              your technology requirements to working digital solutions that
              would result a reduction in manual operations processes, less
              operating expenses, and less dependency on human resources
            </p>
            <Button onClick={() => navigate("/BookADemo")}>Contact Us</Button>
          </SecondTextContent>
        </SecondLayoutContainer>
      </Container>
    </SecondLayout>
  );
};

const ThirdSection = () => {
  const [data, setData] = useState([]);
  const getThirdSectionData = async () => {
    try {
      await fetch(`${LiveBaseURL}api/v1/home_data`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getThirdSectionData();
  }, []);
  return (
    <Carousel style={{ position: "relative" }}>
      {data?.map((ele) => (
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100 "
            style={{ height: "675px" }}
            src={ele.image}
            alt="First slide"
          />
          <Container>
            <SilderTextBox>
              <h2>{ele.title}</h2>
              <h3>{ele.subtitle}</h3>
              <p>{ele.description}</p>
            </SilderTextBox>
          </Container>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const SecondLayoutContainer = styledComponents.div`

display: flex;
@media only screen and (max-width: 992px) {
  flex-direction: column;
}


`;
