import React, { useEffect, useState } from "react";
import { Container, PortfolioContainer, PortfolioWrapper } from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";

export default function Privacy() {
const [data,setData]=useState('')
  var d1 = document.getElementById('dom-render');
  const getDetails = async () => {
   
    try {
      await fetch(`${LiveBaseURL}api/v1/privacy_policy`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
         setData(response?.data)

        })
        .catch((err) => {
          d1.insertAdjacentHTML('afterend', 'Faled to fetch catch')
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
useEffect(() => {
  if(data){
    d1.insertAdjacentHTML('afterend', data)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [data])

  useEffect(() => {
    getDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PortfolioWrapper>
      <Container>
        <PortfolioContainer>
          <div id='dom-render'/>

        </PortfolioContainer>
      </Container>
    </PortfolioWrapper>
  );
}
