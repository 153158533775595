import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonWrapper,
  Container,
  HeaderWrapper,
  HeadWrap,
  NavInner,
  NavWrap,
  SingleLine,
} from "../GobalStyle";
import { ContolioLogo, ContolioLogo1 } from "../Utils/Images";
import "../index.css";
// import { ContolioLogoIcon } from "../Utils/Icons";

export default function Header() {
  const navigate = useNavigate();
  const MyLocation = window.location.pathname;
  
  return (
    <HeadWrap>
      <Container>
        <HeaderWrapper>
          {MyLocation === "/Portfolio" || MyLocation === "/Privacy" ? (
          <img src={ContolioLogo1} alt="Contolio Logo" />
          ) : (
          <img src={ContolioLogo} alt="Contolio Logo" />
          )}
          <ButtonWrapper>
            <Button onClick={() => navigate("/Contact")}>Contact Us</Button>
          </ButtonWrapper>
        </HeaderWrapper>
        <SingleLine>
          <NavWrap>
            <NavInner value={MyLocation}>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "link")}
              >
                <span>Home</span>
              </NavLink>
            </NavInner>

            <NavInner>
              <NavLink
                to="Services"
                className={({ isActive }) => (isActive ? "active" : "link")}
              >
                <span>Services</span>
              </NavLink>
            </NavInner>

            {/* <NavInner>
              <NavLink
                to="Comparison"
                className={({ isActive }) => isActive && "active link"}
              >
                <span>Comparison</span>
              </NavLink>
            </NavInner> */}

            {/* <NavInner>
              <NavLink
                to="/Portfolio"
                className={({ isActive }) => (isActive ? "active" : "link")}
              >
                <span>Portfolio</span>
              </NavLink>
            </NavInner> */}

            <NavInner>
              <NavLink
                to="/Privacy"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Privacy</span>
              </NavLink>
            </NavInner>
          </NavWrap>
        </SingleLine>
      </Container>
    </HeadWrap>
  );
}
